import React, { FunctionComponent } from 'react';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { Badge } from '../../common-components/badge/badge.component';
import { OmniProBadgeSvg } from '../../svg/general.component';
import { ArrowDropDownIcon, PersonIcon } from '../../svg/icons.component';
import { accountToggleProBadge } from './account-dropdown-toggle.css';

export type AccountDropdownToggleProps = {
	firstName?: string;
	isPro?: boolean;
	isOpen?: boolean;
	unreadNotificationCount: number;
};

export const AccountDropdownToggle: FunctionComponent<AccountDropdownToggleProps> = ({
	firstName,
	isPro = false,
	isOpen = false,
	unreadNotificationCount
}) => {
	const isBuild = useIsBuildDomain();
	let theme: string;

	if (!isBuild) {
		theme = 'theme-primary-dark';
	} else {
		theme = isOpen ? 'theme-primary' : 'theme-secondary';
	}

	let accountText = 'Account';

	if (!firstName) {
		accountText = 'Orders & Returns';
	} else if (isBuild) {
		accountText = 'My Account';
	}

	return (
		<div className={`hover-theme-primary ${theme} flex items-center pointer`} data-tracking="nav:menu:My Account">
			{!isPro && isBuild && <PersonIcon className="f1" />}
			<div className="flex-column ml2">
				<span className="f6">
					{firstName ? (
						<>
							Hello, <div className="f6 dib">{firstName}</div>
						</>
					) : (
						<>Log In</>
					)}
				</span>
				<div className={`f5 flex flex-row items-center ${!isBuild && 'b'}`}>
					{!isBuild && unreadNotificationCount > 0 && (
						<div className="db-m dn-l mr1">
							<Badge count={unreadNotificationCount} hideCount={true} noMargin={true} />
						</div>
					)}
					{isPro && <OmniProBadgeSvg className={`${accountToggleProBadge} pr1 pt1 theme-emphasis omniHomeProBadge`} />}
					{accountText}
					<ArrowDropDownIcon className="v-mid omniHome-dn omniHome-dib-l" />
				</div>
			</div>
		</div>
	);
};
