import React, { FunctionComponent } from 'react';
import {
	ACCOUNT_LINK,
	ACCOUNT_LOGIN_LINK,
	ACCOUNT_LOGOUT_LINK,
	ACCOUNT_PROJECTS_LINK,
	ACCOUNT_SALES_REP_LINK,
	ACCOUNT_STORE_CREDIT_LINK,
	ORDER_STATUS_LINK,
	ORDER_SUMMARY_LINK,
	ORDER_TRACK_LINK,
	SET_UP_RETURN_LINK
} from '../../../../constants/links';
import { useCustomer } from '../../../../hooks/apollo/customer/customer.hooks';
import { StyledButton } from '../../../buttons';
import { Link, LinkProps } from '../../../common-components/link/link.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { ProBadgeSvg } from '../../../svg/general.component';
import { proBadge } from './account-section.css';

type AccountLink = LinkProps & { description: string };

const accountLinks: AccountLink[] = [
	{
		url: ACCOUNT_LINK,
		description: 'Account'
	},
	{
		url: ACCOUNT_PROJECTS_LINK,
		description: 'Projects'
	},
	{
		url: ORDER_STATUS_LINK,
		description: 'Orders & Returns'
	},
	{
		url: ORDER_TRACK_LINK,
		description: 'Track Order'
	},
	{
		url: SET_UP_RETURN_LINK,
		description: 'Set Up Return'
	},
	{
		url: ORDER_SUMMARY_LINK,
		description: 'Order Summary'
	}
];
const logOutLink: AccountLink = {
	url: ACCOUNT_LOGOUT_LINK,
	description: 'Log Out'
};

const salesRepLink: AccountLink = {
	url: ACCOUNT_SALES_REP_LINK,
	description: 'My Pro Rep'
};

const storeCreditLink: AccountLink = {
	url: ACCOUNT_STORE_CREDIT_LINK,
	description: 'Store Credit'
};

export const AccountSection: FunctionComponent = () => {
	const { hasAccount, isPro, data: { customer } = {} } = useCustomer();

	function customizeLinks(links: AccountLink[]) {
		let customizedLinks = [...links];

		if (hasAccount) {
			const { salesRepresentative, storeCredit } = customer || {};
			if (salesRepresentative) {
				customizedLinks.push(salesRepLink);
			}
			if (storeCredit) {
				customizedLinks.push(storeCreditLink);
			}
			customizedLinks.push(logOutLink);
		} else {
			customizedLinks = links;
		}
		return customizedLinks;
	}

	return (
		<nav className="mt3 flex flex-column justify-center tc b theme-grey-darker" role="navigation" aria-label="account section">
			{customer && hasAccount ? (
				<div className="bb b--theme-grey-light pb2">
					<div className="mb1 b f6">Hello, {customer.profile.firstName}!</div>
					<div className="f7">
						<div className="flex flex-column items-center center w-60">
							<CloudinaryImage
								options={{
									type: customer.profile.avatar?.imageType,
									width: 128,
									height: 128,
									radius: 'max',
									crop: 'thumb',
									gravity: 'face'
								}}
								bypassLazyLoad={true}
								publicID={customer.profile.avatar?.id || 'mediabase/build_profiles/profiledefault/profiledefault250.png'}
								description={customer.profile.avatar?.description || 'customer avatar'}
							/>
							<div className="mt1 normal">Edit Your Profile</div>
							{isPro && <ProBadgeSvg className={proBadge} />}
						</div>
					</div>
				</div>
			) : (
				<Link url={ACCOUNT_LOGIN_LINK} className="w-100">
					<StyledButton>My Account / Login</StyledButton>
				</Link>
			)}
			{customizeLinks(accountLinks).map(({ url, description }, index) => (
				<Link key={index} url={url} className={'pv2 mt2'} color={'grey-darker'} underline={false}>
					{description}
				</Link>
			))}
		</nav>
	);
};
