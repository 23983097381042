import React, { FunctionComponent } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { ACCOUNT_LINK } from '../../../constants/links';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { Badge } from '../../common-components/badge/badge.component';
import { Link } from '../../common-components/link/link.component';
import { LinkStyleColor } from '../../common-components/link/styled-link.component';
import { PersonIcon } from '../../svg/icons.component';

// TODO: EFDC-16856: reduce use of custom css for header
export const MobileAccountLink: FunctionComponent = () => {
	const { data: customerData } = useCustomer();
	const unreadNotificationCount = customerData?.customer?.unreadNotificationCount ?? 0;
	const enableProjectNotifications = useFeature<boolean>(FEATURE_FLAGS.PROJECT_NOTIFICATIONS);

	const isBuild = useIsBuildDomain();

	const color: LinkStyleColor = isBuild ? 'secondary' : 'primary';

	return (
		<Link
			url={ACCOUNT_LINK}
			className="dib dn-ns hover-theme-primary h-auto-ns w-auto-ns ph2 omniHomeHeaderIcon omniHomeNavHeight100 omniHomeAccountItem"
			color={color}>
			<div className="tc omniHomeMobileNavItemContent">
				{enableProjectNotifications ? (
					<Badge count={unreadNotificationCount} hideCount={true} noMargin={true}>
						<PersonIcon className="f3" />
					</Badge>
				) : (
					<PersonIcon className="f3" />
				)}
				<div>Account</div>
			</div>
		</Link>
	);
};
