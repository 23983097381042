import loadable from '@loadable/component';
import React, { FunctionComponent, useState } from 'react';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { Overlay } from '../../common-components/overlay/overlay.component';
import { AccountDropdownToggle } from '../account-dropdown-toggle/account-dropdown-toggle.component';

export const LoadableAccountDropdownMenu = loadable(
	() => import(/* webpackChunkName: "account-dropdown-menu" */ '../account-dropdown-menu/account-dropdown-menu.component'),
	{
		resolveComponent: ({ AccountDropdownMenu }) => AccountDropdownMenu
	}
);

export const AccountDropdown: FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { hasAccount, data: customerData, isPro } = useCustomer();
	const customer = customerData?.customer;
	const firstName = customer?.profile?.firstName ?? undefined;
	const unreadNotificationCount = customer?.unreadNotificationCount ?? 0;

	const toggleMenu = () => {
		return setIsOpen(!isOpen);
	};
	const closeMenu = () => setIsOpen(false);
	const handleKeys = (e: React.KeyboardEvent<HTMLDivElement>) => {
		// toggle when enter or spacebar hit
		if (e.key === ' ' || e.key === 'Enter') {
			return toggleMenu();
		}
	};

	const onTabOut = () => {
		closeMenu();
	};

	return (
		<div className="relative dib">
			<div
				role="button"
				aria-pressed={isOpen}
				aria-label="account dropdown"
				onClick={toggleMenu}
				tabIndex={0}
				onKeyPress={handleKeys}
				data-testid={'AccountDropDownButton'}>
				<AccountDropdownToggle firstName={firstName} isPro={isPro} unreadNotificationCount={unreadNotificationCount} />
			</div>
			{isOpen && <Overlay onClick={closeMenu} style={'neutral'} />}
			{isOpen && <LoadableAccountDropdownMenu isAuthorized={hasAccount} onTabOut={onTabOut} isPro={isPro} />}
		</div>
	);
};
