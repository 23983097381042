import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import React, { FunctionComponent } from 'react';
import { COOKIE_LAST_PRODUCT, COOKIE_LAST_SEARCH } from '../../../constants/cookies';
import { PAGE_CONTENT_STYLE } from '../../../constants/general';
import { HOMEPAGE_LINK, SUPPORT_EMAIL_US_LINK, SUPPORT_FAQ_LINK } from '../../../constants/links';
import { useTrackPageView } from '../../../hooks/analytics/analytics.hooks';
import { useLiveChat } from '../../../hooks/analytics/live-chat/live-chat.hooks';
import { useContactInfo } from '../../../hooks/apollo/site/site.hooks';
import { TextButton } from '../../buttons';
import { Link } from '../../common-components/link/link.component';
import { Status } from '../../status';
import { SmsIcon, MailIcon, CallIcon } from '../../svg/icons.component';

type FourOFourProps = {
	pageName?: string;
};

export const FourOFour: FunctionComponent<FourOFourProps> = ({ pageName = '404' }) => {
	useTrackPageView({ pageName: `${pageName}:404` });
	const {
		phone: { number: phoneNumber }
	} = useContactInfo();

	const openLiveChat = useLiveChat();

	const { getCookie } = useCookies();
	const lastProduct = getCookie(COOKIE_LAST_PRODUCT);
	const lastSearch = getCookie(COOKIE_LAST_SEARCH);

	return (
		<Status code={404}>
			<div className={PAGE_CONTENT_STYLE}>
				<h2>We seem to have misplaced the page you're looking for.</h2>
				<div className="mt3 bb b--theme-grey-light" />
				<h3>
					<strong>Possible reasons for this error:</strong>
				</h3>
				<ul>
					<li>We moved the location of the page on our servers</li>
					<li>The bookmark you used is outdated</li>
					<li>The URL was entered incorrectly</li>
				</ul>
				<h3>
					<strong>Can't find what you're looking for?</strong>
				</h3>
				<p className="big">Try these other helpful links.</p>
				<ul>
					{lastSearch && (
						<li className="mt2">
							<Link url={lastSearch} underline={true}>
								View Last Search
							</Link>
						</li>
					)}
					{lastProduct && (
						<li className="mt2">
							<Link url={lastProduct} underline={true}>
								View Last Product
							</Link>
						</li>
					)}
					<li className="mt2">
						<Link url={HOMEPAGE_LINK} underline={true}>
							Visit Homepage
						</Link>
					</li>
					<li className="mt2">
						<Link url={SUPPORT_FAQ_LINK} underline={true}>
							Read our FAQs
						</Link>
					</li>
				</ul>
				<div className="mv3 bb b--theme-grey-light" />
				<div className="flex flex-column flex-row-ns">
					<Link url={`tel:${phoneNumber}`} className="mt2" underlineHover={true} color={'secondary'}>
						<div className="flex mr3">
							<CallIcon className="f4" />
							<span className="theme-primary">{phoneNumber}</span>
						</div>
					</Link>
					<div className="mt2">
						<TextButton onClick={openLiveChat}>
							<div className="flex items-center">
								<SmsIcon className="mr2 theme-secondary" />
								<span className="mr3">Chat With Us</span>
							</div>
						</TextButton>
					</div>
					<Link url={SUPPORT_EMAIL_US_LINK} className="mt2" underlineHover={true} color={'secondary'}>
						<div className="flex">
							<MailIcon className="f4" />
							<span className="theme-primary">Email Us</span>
						</div>
					</Link>
				</div>
			</div>
		</Status>
	);
};
