import React, { FunctionComponent } from 'react';
import { ACCOUNT_PRO_LINK } from '../../../constants/links';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { Link } from '../../common-components/link/link.component';
import { OmniProBadgeSvg } from '../../svg/general.component';
import { proBadge } from './pro-signup-link.css';

export const ProSignupLink: FunctionComponent = () => {
	const { hasAccount } = useCustomer();

	return hasAccount ? null : (
		<Link url={ACCOUNT_PRO_LINK} className="dn flex-l flex-row mh2 pt1 hover-theme-primary omniHomeGreyDark-ns" color="secondary">
			<div className="f7 b mb1" data-testid="ab-projects-in-header-pro-banner">
				Are you a Pro?
			</div>
			<div className="f7 ml1">
				<span className="di v-mid">Get</span>
				<OmniProBadgeSvg className={`${proBadge} ph1 theme-emphasis di v-mid omniHomeProBadge`} />
				<span className="di v-mid">Pricing</span>
			</div>
		</Link>
	);
};
