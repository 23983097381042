import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Link, LinkProps } from './link.component';

export type LinkStyleColor = 'primary' | 'secondary' | 'warning' | 'black' | 'white' | 'grey' | 'grey-darker' | 'success' | 'internal';

export type LinkStyleProps = {
	color?: LinkStyleColor;
	underline?: boolean;
	underlineHover?: boolean;
	textAlign?: 'tl' | 'tc' | 'tr' | 'tj';
};

export type StyledLinkProps = PropsWithChildren<LinkStyleProps & LinkProps>;

/**
 * A Link that supports visual enhancements (color, underline etc.)
 */
export const StyledLink: FunctionComponent<StyledLinkProps> = ({ color = 'primary', className = '', underline, ...restProps }) => {
	// we don't use a tachyon here so we can selectively underline by domain/theme
	const underlineStyle = underline === false ? '' : `link-underline-${color}`;
	return <Link className={`${className} ${underlineStyle}`.trim()} color={color} {...restProps} />;
};
